import styled, { css } from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  mix-blend-mode: difference;
  user-select: none;
`;

export const ContentContainer = styled.div`
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  mix-blend-mode: difference;
  user-select: none;
  //min-height: 90vh;
  padding: 4rem 2rem;
  ${MEDIA.MIN_TABLET`
  
      max-width: 80vw;
  `};
  ${MEDIA.MIN_DESKTOP`
  padding: 6rem 6rem;
    max-width: 60vw;
  `};

  ${props =>
    props.home === true &&
    css`
      padding: 4rem 2rem;
      ${MEDIA.MIN_TABLET`
        padding: 2rem 6rem;
        max-width: 90vw;
        `};
      ${MEDIA.MIN_DESKTOP`
      padding: 2rem 6rem;
      max-width: 80vw;
      `};
    `}
`;
export const ContentContainerHome = styled.div`
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  mix-blend-mode: difference;
  user-select: none;
  //min-height: 90vh;
`;
