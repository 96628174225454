import styled from 'styled-components';

export const Container = styled.footer`
  width: 100%;
  align-items: center;
  position: fixed;
  padding: 1rem 2rem;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4rem;
  z-index: 2;
`;
