import { normalize } from 'styled-normalize';
import { createGlobalStyle } from 'styled-components';
import { accent } from 'constants/theme';
import { Rotor } from 'utils/typography';

export default createGlobalStyle`
  ${normalize}

  @font-face{
    font-family: rotor;
    src: url('${Rotor}') format('woff2');
  }

  body {
    color: #516cbc;
    background-color: #ebf6fb;
    color: #212121;
    background-color: #fdfdfd;
    -webkit-font-smoothing: antialiased;
  }

  a {
    color: ${accent};
  }

  p {
    margin-bottom: 2rem;
  }
`;
