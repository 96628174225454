import Typography from 'typography';
import Rotor from '../fonts/rotor-font.woff2';

const typography = new Typography({
  title: 'ypw',
  baseFontSize: '16px',
  baseLineHeight: 1.66,
  scaleRatio: 2.66,
  paragraphSpacing: 1,
  headerFontFamily: ['rotor', 'sans-serif'],
  bodyFontFamily: ['Alef', 'sans-serif'],
  headerWeight: 700,
  blockMarginBottom: 0.4,
  googleFonts: [
    {
      name: 'Alef',
      styles: ['400', '700'],
    },
  ],
  overrideStyles: () => ({
    'h2,h3,h4,h5': {
      fontFamily: ['Alef', 'sans-serif'].join(','),
    },
  }),
});
export default typography;

export { Rotor };
