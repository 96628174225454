import styled, { css } from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Text = styled.span`
  display: block;

  transition: 0.8s linear all;
  transition-delay: 170ms;
  //clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  font-variation-settings: ${({ isVisible, hasBeenVisible }) =>
    isVisible || hasBeenVisible ? '"rtnx" 0' : '"rtnx" 90'};
  //font-variation-settings: "rtnx" 0;
  animation: none;
  animation-duration: 1400ms;
  animation-iteration-count: infinite;
  font-weight: ${({ size }) => () => {
    switch (size) {
      case 'huge':
        return '400';
      case 'large':
        return '400';
      case 'small':
        return '400';
      default:
        return '500';
    }
  }};
  font-size: ${({ size }) => () => {
    switch (size) {
      case 'huge':
        return '3.2rem';
      case 'large':
        return '2.2rem';
      case 'small':
        return '1.2rem';
      default:
        return '2rem';
    }
  }};
  line-height: 1.5;

  ${MEDIA.TABLET`
    font-size: ${({ size }) => () => {
      switch (size) {
        case 'huge':
          return '3.6rem';
        case 'large':
          return '2.6rem';
        case 'small':
          return '1.2rem';
        default:
          return '2rem';
      }
    }};
  `};
  &:hover {
    > span {
      max-width: 100px;
    }
    span span {
      opacity: 1;
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
  }
`;

function createCSS() {
  let styles = '';

  for (let i = 0; i < 5; i += 1) {
    styles += `
       span:nth-of-type(${i}) {
         animation-delay: ${i * 1.5}s;
       }
     `;
  }

  return css`
    ${styles}
  `;
}

export const Expandable = styled.span`
  max-width: 0;
  overflow: hidden;
  display: none;
  -webkit-transition: max-width 0.3s 0.2s cubic-bezier(0, 0, 0, 1);
  transition: max-width 0.3s 0.2s cubic-bezier(0, 0, 0, 1);

  span {
    opacity: 0;
    -webkit-transform: translate(0, -5px);
    transform: translate(0, -5px);
  }
  ${createCSS()}
`;
