import styled from 'styled-components';

export const Container = styled.nav`
  //width: 100%;
  height: 100%;
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    align-items: center;
  }
  li {
    display: inline-block;
    margin: 0;
  }
  a {
    color: #747474;
    font-size: 0.75rem;
    transition: color 0.2s ease;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
      color: inherit;
    }
  }
`;
