import styled, { keyframes } from 'styled-components';

const rotation = keyframes`
    0% {
      //clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
      font-variation-settings: "rtnx" 0;
    }
    50% {
      font-variation-settings: "rtnx" 180;
    }
    100% {
      //clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      font-variation-settings: "rtnx" 0;
    }
`;
const headline = styled.span;
export const Container = styled.div`
  ${headline} {
    transition: 0.8s linear all;
    transition-delay: 170ms;
    //clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    font-variation-settings: ${({ isVisible, hasBeenVisible }) =>
      isVisible || hasBeenVisible ? '"rtnx" 0' : '"rtnx" 90'};
    //font-variation-settings: "rtnx" 0;
    animation: none;
    animation-duration: 1400ms;
    animation-iteration-count: infinite;
  }
  .site-title {
    animation-name: ${rotation};
  }
`;
