import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Head from 'components/head';
import Header from 'components/header';
import { Container, ContentContainer } from './layout.css';
import Footer from 'components/footer';
import GlobalStyle from 'global.css.js';
import getFirebase, { FirebaseContext } from '../firebase/index.js';

class Layout extends Component {
  state = {
    firebase: null,
  };

  componentDidMount() {
    const app = import('firebase/app');
    const database = import('@firebase/database');
    const auth = import('@firebase/auth');

    // eslint-disable-next-line no-undef
    Promise.all([app, database, auth]).then(values => {
      const firebase = getFirebase(values[0]);
      this.setState({ firebase });

      values[0]
        .auth()
        .setPersistence(values[0].auth.Auth.Persistence.LOCAL)
        .then(() => {
          // Existing and future Auth states are now persisted in the current
          // session only. Closing the window would clear any existing state even
          // if a user forgets to sign out.
          // ...
          // New sign-in will be persisted with session persistence.
          return values[0]
            .auth()
            .signInAnonymously()
            .then(user => {
              // Signed in..
            })
            .catch(error => {
              var errorCode = error.code;
              var errorMessage = error.message;
            });
        })
        .catch(error => {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
        });

      values[0].auth().onAuthStateChanged(user => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          var uid = user.uid;

          let onlineUsers = firebase.database.ref('users/' + uid).push();

          onlineUsers.set(user.toJSON());
        } else {
          // User is signed out
          // ...
          firebase.database.ref('users/' + uid).remove();
        }
      });
    });
  }

  render() {
    return (
      <FirebaseContext.Provider value={this.state.firebase}>
        <Container>
          <GlobalStyle />
          <Head />
          <Header />
          <ContentContainer home={this.props.home}>
            {this.props.children}
          </ContentContainer>
          <Footer />
        </Container>
      </FirebaseContext.Provider>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object.isRequired,
  title: PropTypes.string,
  home: PropTypes.bool,
};

const LayoutWithQuery = props => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            siteTitle
          }
        }
      }
    `}
    render={data => <Layout data={data} {...props} />}
  />
);

LayoutWithQuery.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutWithQuery;
