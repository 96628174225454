import React from 'react';
import { Container } from './footer.css';
import Nav from 'components/footer/nav';

const Footer = () => (
  <Container>
    <Nav />
  </Container>
);

Footer.propTypes = {};

export default Footer;
