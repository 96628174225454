//
// import firebase from '@firebase/app';
// import '@firebase/database';
// import '@firebase/auth';

const config = {
  apiKey: 'AIzaSyAC1Q-vwpAXgXyJjIQbxAw_HVKnfuaiMRU',
  authDomain: 'portfolio-2019-7d0d4.firebaseapp.com',
  databaseURL: 'https://portfolio-2019-7d0d4.firebaseio.com',
  projectId: 'portfolio-2019-7d0d4',
  storageBucket: 'portfolio-2019-7d0d4.appspot.com',
};
class Firebase {
  constructor(app) {
    // Initialize Firebase
    app.initializeApp(config);

    this.database = app.database();
    this.auth = app.auth();
  }

  lastLines = () => this.database.ref('lines').limitToLast(500);
}

let firebase;

function getFirebase(app, database, auth) {
  if (!firebase) {
    firebase = new Firebase(app, database, auth);
  }

  return firebase;
}

export default getFirebase;
