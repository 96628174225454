import styled from 'styled-components';

export const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 1rem 2rem;
  height: 5rem;
  z-index: 2;

  a {
    color: #757575;
    transition: color 0.2s ease;
    text-decoration: none;

    &:hover {
      color: inherit;
    }
  }
`;

export const HeaderLeft = styled.div``;

export const Logo = styled.div`
  display: flex;
  flex-direction: column;
  //flex-basis: 20%;
`;
